<template>
    <a-modal
            title="用户详情"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @cancel="$emit('close')"
            @ok="handleSubmit"
            destroyOnClose
    >
        <a-form
                :form="form"
                v-bind="formItemLayout"
                v-if="visible"
        >
            <a-form-item label="增加天数">
                <a-input v-model="addTime" placeholder="请输入增加的有效天数" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/practice'
    export default {
        name: 'addActive',
        props: [ 'visible', 'data' ],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        watch:{
            visible(val){
                if(val){
                    if(this.data){
                        this.addTime = this.data.addTime;
                        this.userId = this.data.uid
                    }
                }
            }
        },
        data(){
            return{
                confirmLoading: false,
                loading: false,
                addTime: '',
                userId: '',
                formItemLayout: {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18 },
                },
            }
        },
        methods:{
            handleSubmit(){
                if(!this.addTime){
                    this.$message.error('请输入给用户增加的有效天数')
                    return
                }
                this.confirmLoading = true
                http.backadd_active({addTime: this.addTime,userId: this.userId}).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.confirmLoading = false
                }).catch((error)=>{
                    console.log(error)
                    this.confirmLoading = false
                })
            },
            beforeUpload(){

            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.imageUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            handleChange(){

            }
        }
    }
</script>
